<template>
  <div class="detail container space">
    <div class="img-wrapper">
      <img src="@/assets/brand/jde/jde_01.png" alt="">
      <img src="@/assets/brand/jde/jde_02.png" alt="">
      <img src="@/assets/brand/jde/jde_03.png" alt="">
      <img src="@/assets/brand/jde/jde_04.png" alt="">
      <img src="@/assets/brand/jde/jde_05.png" alt="">
    </div>
  </div>
</template>

<style lang="scss">
  
</style>

<script>
export default {
  data(){
    return {
    }
  },
}
</script>
<style lang="scss">
 .detail {
    .img-wrapper {
      width: 100%;
    }
    img {
      width: 100%;
      max-width: 100%;
    }
  }
  @media (max-width: 768px){
  .detail .img-wrapper {
    margin-top: 60px;
    padding: 0 15px;
  }
}
</style>